import {
  ProductLabourCostType,
  ProductMeasurementJob,
  ProductMeasurementMaterial,
  ProductProductionRateType,
  ProductType,
} from "generated/graphql";
import * as z from "zod";

export const calculateAreaMappings = {
  [ProductType.CRACK_SEAL]: (getValues, setValue) => {
    const attributes = getValues("attributes");
    const {
      poundsPerBlock,
      poundsPerGallon,
      crackWidth,
      crackDepth,
      overbandWidth,
      overbandThickness,
    } = attributes;
    const value =
      ((poundsPerBlock / poundsPerGallon) * 231) /
      (crackWidth * crackDepth * 12 + overbandWidth * overbandThickness * 12);
    setValue("areaCoverage", +value.toFixed(2));
  },
  [ProductType.GRADING]: (getValues, setValue) => {
    const attributes = getValues("attributes");
    const jobMeasurement = getValues("measurementJob");
    const { poundsPerCubicYard, applicationRate } = attributes;

    const cubicYardPerTon = 2000 / poundsPerCubicYard;
    const cubicFeetPerTon = cubicYardPerTon * 27;

    if (jobMeasurement === ProductMeasurementJob.SQUARE_YARD) {
      // if the job measurment is set to Square yards
      const cubicYardsPerSquareYard = ((applicationRate / 12) * 9) / 27;
      const areaCoverage = cubicYardPerTon / cubicYardsPerSquareYard;
      setValue("areaCoverage", +areaCoverage.toFixed(2));
    } else {
      // if the job measurment is set to Square feet
      const cubicYardsPerSquareFoot = (12 * 12 * applicationRate) / 1728;
      const areaCoverage = cubicFeetPerTon / cubicYardsPerSquareFoot;
      setValue("areaCoverage", +areaCoverage.toFixed(2));
    }
  },
  [ProductType.MASTIC]: (getValues, setValue) => {
    const attributes = getValues("attributes");
    const {
      poundsPerBlock = null,
      poundsPerGallon = null,
      crackWidth = null,
      crackDepth = null,
      overbandWidth = null,
      overbandThickness = null,
    } = attributes ?? {};

    try {
      const value =
        ((poundsPerBlock / poundsPerGallon) * 231) /
        (crackWidth * crackDepth * 12 + overbandWidth * overbandThickness * 12);
      if (!Number.isNaN(value)) {
        setValue("areaCoverage", +Math.floor(value).toFixed(2));
      } else {
        setValue("areaCoverage", 0);
      }
    } catch (e) {
      // show error
    }
  },
};

export const requiresPlant = [
  ProductType.ASPHALT,
  ProductType.MICROSURFACING,
  ProductType.SLURRY_SEAL,
  ProductType.EXCAVATE_OUT,
  ProductType.CONCRETE_FLATWORK,
  ProductType.CONCRETE_CURB_OR_GUTTER,
  ProductType.CURB_WALL,
  ProductType.CONCRETE_WATERWAY,
  ProductType.GRADING,
  ProductType.CONCRETE_FOOTING,
  ProductType.CONCRETE_WALL,
  ProductType.CONCRETE_COLUMN,
];

export const requiresTrucking = [
  ProductType.MICROSURFACING,
  ProductType.SLURRY_SEAL,
  ProductType.EXCAVATE_OUT,
  ProductType.ASPHALT,
  ProductType.GRADING,
];

export const concreteProducts = [
  ProductType.CONCRETE_CURB_OR_GUTTER,
  ProductType.CONCRETE_FLATWORK,
  ProductType.CONCRETE_WATERWAY,
  ProductType.CURB_WALL,
  ProductType.CONCRETE_FOOTING,
  ProductType.CONCRETE_WALL,
  ProductType.CONCRETE_COLUMN,
];

const plantIdRequired = z.object({
  plantProductId: z.string().min(1, "Plant Product Id is Required"),
});

const productionRateTypeRequired = z.object({ productionRateType: z.boolean().optional() });

export const productTypeValidationMap = {
  [ProductType.ASPHALT]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.CONCRETE_CURB_OR_GUTTER]: z.object({
    attributes: z.object({
      curbWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Width is required and cannot be less than .01 inches"),
      curbHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Height is required and cannot be less than .01 inches"),
      gutterWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Width is required and cannot be less than .01 inches"),
      gutterHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Height is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.CONCRETE_FLATWORK]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.CONCRETE_WATERWAY]: z.object({
    attributes: z.object({
      curbWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Width is required and cannot be less than .01 inches"),
      curbHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Height is required and cannot be less than .01 inches"),
      gutterWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Width is required and cannot be less than .01 inches"),
      gutterHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Height is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.CRACK_SEAL]: z.object({
    attributes: z.object({
      crackDepth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Crack Depth is required and cannot be less than .01 inches"),
      crackWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Crack Width is required and cannot be less than .01 inches"),
      overbandWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Overband Width is required and cannot be less than .01 inches"),
      overbandThickness: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Overband Thickness is required and cannot be less than .01 inches"),
      poundsPerGallon: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Pounds Per Gallon is required and cannot be less than .01 inches"),
      poundsPerBlock: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Pounds Per Block is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.CURB_WALL]: z.object({
    attributes: z.object({
      curbWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Width is required and cannot be less than .01 inches"),
      curbHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Curb Height is required and cannot be less than .01 inches"),
      gutterWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Width is required and cannot be less than .01 inches"),
      gutterHeight: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Gutter Height is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.EXCAVATE_OUT]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.GRADING]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
      poundsPerCubicYard: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Pounds Per Gallon is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.MASTIC]: z.object({
    attributes: z.object({
      poundsPerGallon: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Pounds Per Gallon is required and cannot be less than .01 inches"),
      poundsPerBlock: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Pounds Per Block is required and cannot be less than .01 inches"),
      crackDepth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Crack Depth is required and cannot be less than .01 inches"),
      crackWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Crack Width is required and cannot be less than .01 inches"),
      overbandWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Overband Width is required and cannot be less than .01 inches"),
      overbandThickness: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Overband Thickness is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.MICROSURFACING]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.SEALCOAT]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.001, "Application Rate is required and cannot be less than .001 inches"),
    }),
  }),
  [ProductType.SLURRY_SEAL]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Application Rate is required and cannot be less than .01 inches"),
    }),
  }),
  [ProductType.STRIPING]: z.object({
    attributes: z.object({
      applicationRate: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.001, "Application Rate is required and cannot be less than .001 inches"),
      lineWidth: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.001, "Line Width is required and cannot be less than .001 inches"),
    }),
  }),
  [ProductType.CONCRETE_FOOTING]: z.object({
    attributes: z.object({
      width: z.coerce.number({ invalid_type_error: "Required" }).min(0.01, "Width is required"),
      depth: z.coerce.number({ invalid_type_error: "Required" }).min(0.01, "Depth is required"),
    }),
  }),
  [ProductType.CONCRETE_WALL]: z.object({
    attributes: z.object({
      width: z.coerce.number({ invalid_type_error: "Required" }).min(0.01, "Width is required"),
      height: z.coerce.number({ invalid_type_error: "Required" }).min(0.01, "Height is required"),
    }),
  }),
  [ProductType.CONCRETE_COLUMN]: z.object({
    attributes: z.object({
      diameter: z.coerce
        .number({ invalid_type_error: "Required" })
        .min(0.01, "Diameter is required"),
      height: z.coerce.number({ invalid_type_error: "Required" }).min(0.01, "Height is required"),
    }),
  }),
};

export const baseProductSchemaSubcontracted = z.object({
  name: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  externalProductName: z.string().optional(),
  indirectPercentage: z.number({ invalid_type_error: "Indirect Percentage is required" }).min(0),
  overheadPercentage: z.number({ invalid_type_error: "Overhead Percentage is required" }).min(0),
  markupPercentage: z.number({ invalid_type_error: "Markup Percentage is required" }).min(0),
  statementOfWork: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  subcontracted: z.boolean(),
  organizationProductTypeId: z.string().min(1, "Required"),
  measurementJob: z.nativeEnum(ProductMeasurementJob),
});
export const baseProductSchemaUnitPrice = z.object({
  name: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  externalProductName: z.string().optional(),
  indirectPercentage: z.number({ invalid_type_error: "Indirect Percentage is required" }).min(0),
  overheadPercentage: z.number({ invalid_type_error: "Overhead Percentage is required" }).min(0),
  markupPercentage: z.number({ invalid_type_error: "Markup Percentage is required" }).min(0),
  statementOfWork: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  unitPriceProduct: z.boolean(),
  unitPrice: z.number({ invalid_type_error: "Required" }).min(0),
  organizationProductTypeId: z.string().min(1, "Required"),
  dailyUnitsComplete: z.number().positive(),
  measurementJob: z.nativeEnum(ProductMeasurementJob),
});

export const baseProductSchema = z.object({
  crewId: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  name: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  externalProductName: z.null().or(z.string().optional()),
  measurementMaterial: z.nativeEnum(ProductMeasurementMaterial, {
    invalid_type_error: `Required`,
  }),
  costMaterial: z.null().or(z.number({ invalid_type_error: "Required" }).min(0)),
  measurementJob: z.nativeEnum(ProductMeasurementJob),
  labourCostType: z.nativeEnum(ProductLabourCostType),
  dailyUnitsComplete: z.number().positive(),
  costMinimumProposal: z
    .null({ invalid_type_error: "Minimum Proposal Cost is required" })
    .or(z.number({ invalid_type_error: "Minimum Proposal Cost is required" }).min(0)),
  costMinimumProduct: z.number({ invalid_type_error: "Minimum Product Cost is required" }).min(0),
  indirectPercentage: z.number({ invalid_type_error: "Indirect Percentage is required" }).min(0),
  overheadPercentage: z.number({ invalid_type_error: "Overhead Percentage is required" }).min(0),
  markupPercentage: z.number({ invalid_type_error: "Markup Percentage is required" }).min(0),
  statementOfWork: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  plantId: z.string().optional(),
  plantProductId: z.string().optional(),
  subcontracted: z.boolean(),
});

export const baseSchemaMap = {
  [ProductType.ASPHALT]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.ASPHALT])
    .merge(plantIdRequired)
    .merge(productionRateTypeRequired),
  [ProductType.CONCRETE_CURB_OR_GUTTER]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_CURB_OR_GUTTER])
    .merge(plantIdRequired),
  [ProductType.CONCRETE_FLATWORK]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_FLATWORK])
    .merge(plantIdRequired),
  [ProductType.CONCRETE_WATERWAY]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_WATERWAY])
    .merge(plantIdRequired),
  [ProductType.CONCRETE_FOOTING]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_FOOTING])
    .merge(plantIdRequired),
  [ProductType.CONCRETE_WALL]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_WALL])
    .merge(plantIdRequired),
  [ProductType.CONCRETE_COLUMN]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CONCRETE_COLUMN])
    .merge(plantIdRequired),
  [ProductType.CRACK_SEAL]: baseProductSchema.merge(
    productTypeValidationMap[ProductType.CRACK_SEAL]
  ),
  [ProductType.CURB_WALL]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.CURB_WALL])
    .merge(plantIdRequired),
  [ProductType.EXCAVATE_OUT]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.EXCAVATE_OUT])
    .merge(plantIdRequired)
    .merge(productionRateTypeRequired),
  [ProductType.GRADING]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.GRADING])
    .merge(plantIdRequired)
    .merge(productionRateTypeRequired),
  [ProductType.MASTIC]: baseProductSchema.merge(productTypeValidationMap[ProductType.MASTIC]),
  [ProductType.MICROSURFACING]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.MICROSURFACING])
    .merge(plantIdRequired),
  [ProductType.SEALCOAT]: baseProductSchema.merge(productTypeValidationMap[ProductType.SEALCOAT]),
  [ProductType.SLURRY_SEAL]: baseProductSchema
    .merge(productTypeValidationMap[ProductType.SLURRY_SEAL])
    .merge(plantIdRequired)
    .merge(productionRateTypeRequired),
  [ProductType.STRIPING]: baseProductSchema.merge(productTypeValidationMap[ProductType.STRIPING]),
  [ProductType.GENERIC]: baseProductSchema.merge(
    z.object({
      areaCoverage: z
        .number({
          required_error: "Area Coverage is Required",
          invalid_type_error: "Area Coverage is Required",
        })
        .positive("Area Coverage should be positive"),
    })
  ),
};

export const getProductCreateSchema = ({ productType }: { productType: ProductType }) =>
  baseSchemaMap[productType].merge(
    z.object({
      organizationProductTypeId: z.string().min(1, "Required"),
      equipmentPieces: z
        .object({
          connect: z.array(
            z.object({ id: z.string().min(1), quantity: z.number().positive(), name: z.string() })
          ),
        })
        .optional(),
      additionalCosts: z.object({
        connect: z.array(
          z.object({
            id: z.string().min(1),
            type: z.any(), //https://app.clickup.com/t/866941ydt
            name: z.string(),
          })
        ),
      }),
    })
  );

export const defaultValues = {
  name: null,
  externalProductName: null,
  costMaterial: null,
  measurementMaterial: null,
  attributes: {
    applicationRate: null,
    curbWidth: null,
    curbHeight: null,
    gutterWidth: null,
    gutterHeight: null,
    crackWidth: null,
    crackDepth: null,
    overbandWidth: null,
    overbandThickness: null,
    poundsPerGallon: null,
    poundsPerBlock: null,
    lineWidth: null,
  },
  costMinimumProposal: null,
  costMinimumProduct: null,
  markupPercentage: null,
  indirectPercentage: null,
  overheadPercentage: null,
  statementOfWork: null,
  subcontracted: false,
  unitPriceProduct: false,
};

export const getDefaultValues = (initialDefaultValues) => ({
  ...defaultValues,
  ...initialDefaultValues,
});
