import { zodResolver } from "@hookform/resolvers/zod";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  TextareaAutosize,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import {
  PreviewProposalMutationVariables,
  ProposalLayout,
  SendProposalMutationVariables,
} from "generated/graphql";
import useSendProposal from "hooks/proposals/useSendProposal";
import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { getRoute } from "utils/routing";
import { z } from "zod";
import ProposalContext from "./providers/ProposalContextProvider";
import usePreviewProposal from "hooks/proposals/usePreviewProposal";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useGetOrganization from "hooks/organization/useGetOrganization";
import CustomMultipleSelect from "components/Shared/CustomMultipleSelect/CustomMultipleSelect";
import { getOrganizationDocumentName } from "modules/organization/UpdateOrganization/DocumentItem";
import useGetOrganizationProposalContent from "hooks/organization/useGetorganizationProposalContent";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import { DefinedButtons } from "components/Sidebar";
import { SidebarContext } from "components/Sidebar/sidebarContext";
import Modal from "modules/Modal/Modal";
import ProposalStageSummary from "./ProposalStageSummary";
import { ProposalFormsContext } from "./providers/ProposalFormsContextProvider";

const schema = z.object({
  proposalId: z.string().min(1, "Proposal ID is required"),
  layout: z.nativeEnum(ProposalLayout),
  proposalNotes: z.string().nullable(),
  emails: z.array(z.string().min(1, "Email is required")),
  removeUnitPriceAndQuantity: z.boolean().optional(),
  removeIndividualLineItemPricing: z.boolean().optional(),
  attachments: z.array(z.string()).optional(),
  acceptanceOverride: z.string().optional().or(z.null()),
});

export default function SendProposal() {
  const { data } = useGetOrganization();
  const { proposalContent, loading: getContentLoading } = useGetOrganizationProposalContent();
  const { proposal } = useContext(ProposalContext);
  const navigate = useNavigate();
  const [sendProposal, { isSuccess }] = useSendProposal({ id: proposal.id });
  const [previewProposal, { isSuccess: previewSuccess }] = usePreviewProposal();
  const [error, setError] = useState<null | string>(null);
  type AcceptFnProps = "send" | "preview";
  const [acceptFnType, setAcceptFnType] = useState<AcceptFnProps>("preview");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const allDocuments = useMemo(() => {
    if (!data || !proposal) return [];
    return [...data?.documents, ...proposal?.attachments];
  }, [data, proposal]);

  const getDocumentLabel = useCallback(
    (id) => {
      if (!allDocuments) return "";
      const document = allDocuments?.find((d) => d.id === id);
      return getOrganizationDocumentName(document);
    },
    [data]
  );

  const { clickedButton } = useContext(SidebarContext);

  useEffect(() => {
    if (clickedButton == DefinedButtons.ProposalPreview) {
      handlePreviewProposal({
        proposalId: proposal.id,
        layout: methods.getValues("layout") as ProposalLayout,
        removeUnitPriceAndQuantity: methods.getValues("removeUnitPriceAndQuantity"),
        removeIndividualLineItemPricing: methods.getValues("removeIndividualLineItemPricing"),
        attachments: methods.getValues("attachments"),
        acceptanceOverride: methods.getValues("acceptanceOverride"),
      });
    }
    if (clickedButton == DefinedButtons.ProposalSummaryView) {
      setOpenModal(true);
    }
  }, [clickedButton]);

  const defaultValues = useMemo(() => {
    return {
      proposalId: proposal.id,
      layout: (proposal.layout ?? ProposalLayout.SIMPLE) as ProposalLayout,
      proposalNotes: proposal?.proposalNotes ?? "",
      emails: [proposal.contact.email],
      removeUnitPriceAndQuantity: proposal.removeUnitPriceAndQuantity,
      removeIndividualLineItemPricing: proposal.removeIndividualLineItemPricing,
      attachments: proposal.attachments?.map((at) => at.id),
      acceptanceOverride: proposal.acceptanceOverride,
    };
  }, [proposal]);
  const methods = useForm<SendProposalMutationVariables>({
    resolver: zodResolver(schema),
    defaultValues,
    values: defaultValues,
  });

  const { productsFormsStatus } = useContext(ProposalFormsContext);
  const productsWithPendingChanges = Object.keys(productsFormsStatus).filter(
    (productId) => productsFormsStatus[productId].isDirty
  );
  const proposalIsDirty = productsWithPendingChanges?.length > 0;
  const handleSendProposal = useCallback(
    async (variables: SendProposalMutationVariables) => {
      setAcceptFnType("send");
      const result = await sendProposal({
        variables,
      });

      if (isSuccess(result.data)) {
        navigate(getRoute("proposals.info", [["proposalId", proposal.id]]));
        return true;
      }
      handleJobSizeError(result);
    },
    [sendProposal]
  );

  // https://app.clickup.com/t/8693kbaep
  const handleJobSizeError = (result) => {
    if (
      // @ts-ignore
      result?.name === "ApolloError" &&
      // @ts-ignore
      result?.message?.indexOf("Please input a job size.") !== -1
    ) {
      // @ts-ignore
      setError(result.message);
      setOpen(true);
    }
  };

  const handlePreviewProposal = useCallback(
    async (variables: PreviewProposalMutationVariables) => {
      setAcceptFnType("preview");
      const result = await previewProposal({
        variables,
      });
      if (previewSuccess(result.data) && result.data?.previewProposal) {
        setError(null);
        window.open(result.data.previewProposal, "_blank");
        return true;
      }
      handleJobSizeError(result);
    },
    [previewProposal]
  );

  type ActionMap = {
    [K in AcceptFnProps]: {
      method: (
        variables: SendProposalMutationVariables | PreviewProposalMutationVariables
      ) => Promise<boolean>;
      variables: SendProposalMutationVariables | PreviewProposalMutationVariables;
    };
  };

  const acceptFn = useCallback(async () => {
    const actionMap: ActionMap = {
      send: {
        method: handleSendProposal,
        variables: { ...methods.getValues(), forceSend: true },
      },
      preview: {
        method: handlePreviewProposal,
        variables: {
          proposalId: proposal.id,
          layout: methods.getValues("layout") as ProposalLayout,
          removeUnitPriceAndQuantity: methods.getValues("removeUnitPriceAndQuantity"),
          removeIndividualLineItemPricing: methods.getValues("removeIndividualLineItemPricing"),
          forceSend: true,
          attachments: methods.getValues("attachments"),
          acceptanceOverride: methods.getValues("acceptanceOverride"),
        },
      },
    };

    const { method, variables } = actionMap[acceptFnType];

    return method(variables);
  }, [handlePreviewProposal, handleSendProposal, acceptFnType]);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <MDBox component="form" role="form" onSubmit={methods.handleSubmit(handleSendProposal)}>
        <Accordion>
          <AccordionSummary
            sx={{
              backgroundColor: "rgba(100, 100, 150, 0.2)",
              marginTop: "30px",
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <MDTypography>Proposal Options</MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox
              p={3}
              component="form"
              role="form"
              onSubmit={methods.handleSubmit(handleSendProposal)}
            >
              <MDBox pb={6}>
                <FormProvider {...methods}>
                  <MDBox>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Controller
                              name="removeUnitPriceAndQuantity"
                              control={methods.control}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => field.onChange(e.target.checked)}
                                />
                              )}
                            />
                          }
                          label="Remove Unit Price And Quantity"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Controller
                              name="removeIndividualLineItemPricing"
                              control={methods.control}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => field.onChange(e.target.checked)}
                                />
                              )}
                            />
                          }
                          label="Remove Individual Line Item Pricing"
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <Recipients />
                  <Grid container spacing={3} mt={1} mb={4}>
                    <Grid item xs={12} md={6}>
                      {data?.documents && (
                        <Controller
                          name="attachments"
                          control={methods.control}
                          render={({ field }) => (
                            <CustomMultipleSelect
                              {...field}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e?.map((x) => x.value as string));
                              }}
                              label="Proposal Attachments"
                              value={
                                // @ts-ignore
                                field.value?.map((s) => ({
                                  label: getDocumentLabel(s),
                                  value: s,
                                })) ?? ([] as const)
                              }
                              options={allDocuments?.map((d) => ({
                                value: d.id,
                                label: getDocumentLabel(d.id),
                              }))}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ marginBottom: "6px" }} />
                      <CustomSelect
                        control={methods.control}
                        fullWidth
                        selectVariant="standard"
                        data={enumToValueOptions(ProposalLayout)}
                        {...methods.register("layout")}
                        label="Proposal Format"
                      />
                    </Grid>
                  </Grid>
                  <MDTypography variant="h5" mt={3}>
                    Internal Proposal Notes
                  </MDTypography>
                  <MDBox mt={2}>
                    <TextareaAutosize
                      {...methods.register("proposalNotes")}
                      minRows={10}
                      style={{ minWidth: "100%" }}
                    />
                  </MDBox>
                  <MDBox>
                    <ActionConfirmDialog
                      open={open}
                      setOpen={setOpen}
                      handleAccept={onAccept}
                      handleCancel={onCancel}
                      title="Confirmation"
                      content={
                        error?.replace("Please input a job size.", "Proceed?") ||
                        "Are you sure you want to proceed?"
                      }
                    />
                  </MDBox>
                  <Accordion>
                    <AccordionSummary
                      sx={{
                        backgroundColor: "rgba(100, 100, 150, 0.2)",
                        marginTop: "30px",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <MDTypography>Overwrite Proposal Acceptance</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12}>
                          {proposalContent && (
                            <Controller
                              control={methods.control}
                              name="acceptanceOverride"
                              render={({ field, fieldState: { error } }) => (
                                <>
                                  <WYSIWYG
                                    type="proposal"
                                    content={
                                      proposal.acceptanceOverride ??
                                      proposalContent.find((type) => type.type === "ACCEPTANCE")
                                        ?.content
                                    }
                                    onChange={(content) => field.onChange(content)}
                                    title="Overwrite Proposal Acceptance"
                                    tooltip="Overwrite Proposal Acceptance"
                                  />
                                </>
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </FormProvider>
              </MDBox>
            </MDBox>
          </AccordionDetails>
        </Accordion>
        <MDBox my={3} display="flex" justifyContent="space-between">
          <Tooltip
            title={
              proposalIsDirty
                ? "Please save your changes and review for accuracy to preview the proposal"
                : ""
            }
            placement="top"
          >
            <span>
              <MDButton
                color="blue"
                onClick={async (e) => {
                  e.preventDefault();
                  await handlePreviewProposal({
                    proposalId: proposal.id,
                    layout: methods.getValues("layout") as ProposalLayout,
                    removeUnitPriceAndQuantity: methods.getValues("removeUnitPriceAndQuantity"),
                    removeIndividualLineItemPricing: methods.getValues(
                      "removeIndividualLineItemPricing"
                    ),
                    attachments: methods.getValues("attachments"),
                    acceptanceOverride: methods.getValues("acceptanceOverride"),
                  });
                }}
                disabled={proposalIsDirty}
              >
                Review Proposal
              </MDButton>
            </span>
          </Tooltip>

          <Tooltip
            title={
              proposalIsDirty
                ? "Please save your changes and review for accuracy to send the proposal"
                : ""
            }
            placement="top"
          >
            <span>
              <MDButton color="success" variant="gradient" type="submit" disabled={proposalIsDirty}>
                Send Proposal
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </MDBox>
      <Modal open={openModal} onClose={onClose} styles={{ maxWidth: "auto" }}>
        <ProposalStageSummary onCloseButton={onClose} />
      </Modal>
    </>
  );
}

function Recipients() {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `emails`,
  });

  return (
    <>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={6} lg={4}>
          <MDTypography variant="h6">Main Contact Recipient</MDTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <FormField {...register(`emails.0`)} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox display="flex" width="100%" alignItems="center">
            <MDTypography variant="h6">Additional Recipients</MDTypography>
            <IconButton onClick={() => append("")}>
              <Icon>add_circle</Icon>
            </IconButton>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {fields
            .slice(1, Infinity)
            .reverse()
            .map((field, i) => (
              <MDBox key={i} display="flex" width="100%" alignItems="center">
                <FormField
                  label="Additional Recipient"
                  {...register(`emails.${i + 1}`)}
                  error={errors["emails"]?.[i + 1]}
                />
                <IconButton onClick={() => remove(i + 1)}>
                  <Icon color="error">delete</Icon>
                </IconButton>
              </MDBox>
            ))}
        </Grid>
      </Grid>
    </>
  );
}
